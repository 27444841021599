<template>
  <div class="mt-5">

    <div class="faces-intro pl-md-3 pr-md-3">
      <div class="container-fluid wrapper-1800 pl-4 pr-4">
        <div class="row">
          <div class="col-12 col-md-3 col-xl-2">
            <h1>
              The <br class="d-none d-md-block">
              Faces <br class="d-none d-md-block">
              of Hult <br class="d-none d-md-block">
            </h1>
          </div>
          <div class="col-12 col-md-6">
            <p class="lead">
              No one student could represent our global community in all its diversity—so we snapped a selection, for you to get to know some of the changemakers who arrived on campus before you. From undergrads to executives, from Australia to Zimbabwe, the Hult community is all-encompassing in its reach, talent, and ambition.
            </p>
          </div>
        </div>
        </div>
    </div>

    <div class="pl-md-3 pr-md-3 mb-5">
      <div class="container-fluid wrapper-1800 faces-grid">
    <div class="row no-gutters">
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student01-Marayam.jpg" alt="Face of Hult" height="689">
          <h4>
            Maryam Bashar Siddiqui<br>
            Indian<br>
            Class of 2021
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student02-Minh.jpg" alt="Face of Hult" height="689">
          <h4>
            Minh Phùng <br>
            Vietnamese<br>
            Class of 2022
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student03-Baba.jpg" alt="Face of Hult" height="689">
          <h4>
            Abdul-Malik "Baba" Toure<br>
            French<br>
            Class of 2022 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student04-Maxine.jpg" alt="Face of Hult" height="689">
          <h4>
            Maxine Fish<br>
            Canadian<br>
            Class of 2021
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student05-Tea.jpg" alt="Face of Hult" height="689">
          <h4>
            Tea Hondus<br>
            British<br>
            Class of 2022 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student06-Dev.jpg" alt="Face of Hult" height="689">
          <h4>
            Dev Smitkumar Kaneria<br>
            Indian<br>
            Class of 2021 
          </h4>
        </div>
      </div>
    
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student07-Christine.jpg" alt="Face of Hult" height="689">
          <h4>
            Christine Gabrielle Dimen Lasa<br>
            Filipino<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student08-Ben.jpg" alt="Face of Hult" height="689">
          <h4>
            Benjamin Chen-Zorn<br>
            Austrian/German<br>
            Class of 2021
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student09-Sahara.jpg" alt="Face of Hult" height="689">
          <h4>
            Sahara Alyse McRoy<br>
            American<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student10-Nicholas.jpg" alt="Face of Hult" height="689">
          <h4>
            Nicolas Routtier<br>
            French<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student11-Sarah.jpg" alt="Face of Hult" height="689">
          <h4>
            Sarah Kad-Bay Rodriguez<br>
            Venezuelan/Spanish<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student12-Daniel.jpg" alt="Face of Hult" height="689">
          <h4>
            Dan Shomer<br>
            Israeli/British<br>
            Class of 2022 
          </h4>
        </div>
      </div>
    
    
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student13-Vera.jpg" alt="Face of Hult" height="689">
          <h4>
            Vera Ortega<br>
            Dominican Republic<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student14-Marco.jpg" alt="Face of Hult" height="689">
          <h4>
            Pasquale Marco Matera<br>
            Italian<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student15-Emily.jpg" alt="Face of Hult" height="689">
          <h4>
            Emily Sue Gallagher<br>
            American<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student16-Kenneth.jpg" alt="Face of Hult" height="689">
          <h4>
            Kenneth Kashif Thomas<br>
            American<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student17-Kudzai.jpg" alt="Face of Hult" height="689">
          <h4>
            Kudzai Zindi<br>
            Zimbabwean/British<br>
            Class of 2022 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student18-Farhan.jpg" alt="Face of Hult" height="689">
          <h4>
            Farhan Khan<br>
            Pakistani<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student19-Peter.jpg" alt="Face of Hult" height="689">
          <h4>
            Peter Waxman<br>
            American<br>
            Class of 2021 
          </h4>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student20-Jimena.jpg" alt="Face of Hult" height="689">
          <h4>
            Jimena Perez Orejas<br>
            Mexican<br>
            Class of 2023 
          </h4>
        </div>
      </div>
    
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student21-Anna.jpg" alt="Face of Hult" height="689">
          <h4>
            Anna Katharina Höhn<br>
            German<br>
            Class of 2021  
          </h4>
        </div>
      </div>
    
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student22-Anddrea.jpg" alt="Face of Hult" height="689">
          <h4>
            Andreea Alecsandra Nan<br>
            Romanian<br>
            Class of 2021  
          </h4>
        </div>
      </div>
    
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student23-Mario.jpg" alt="Face of Hult" height="689">
          <h4>
            Mario Calabresi<br>
            Italian<br>
            Class of 2021
          </h4>
        </div>
      </div>
    
      <div class="col-6 col-md-4 col-lg-3">
        <div class="box-face">
          <img src="/img/faces/large/hult-student24-Olga.jpg" alt="Face of Hult" height="689">
          <h4>
            Olga Babenko<br>
            Swiss/Ukrainian<br>
            Class of 2022 
          </h4>
        </div>
      </div>
    
    
      
    </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Faces',
  data() {
    return {
      bgClass: "",
    }
  },
  computed: {
  },
  methods: {

  },
  mounted() {
    this.$store.state.home = false;
    this.$store.state.logoHide = false;
    this.$store.state.navHide = false;
    this.$store.state.bodyBackground = "bg-standard bg-solid";
  }
}
</script>